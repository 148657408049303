import React, { useState } from "react";
import LogAuth from "./components/LogAuth";

function App() {
  
  return (
      <div>
        <LogAuth />
      </div>
    
  );
}

export default App;
